<template>
  <div>
  <div
  class="uploadProfile-container"
  @click="handleCheckBottom"
  >
    <input class="input" type="file" name="upload" id="uploadImg"
    @change="handleCheckFile"
    ref="inputContent"
    accept="image/png, image/jpeg,.pdf">
    <div for="uploadImg" class="upload-container">
      <img class="upload-left-icon" :src="IconSrc" alt="" />
      <div>
        <div class=" text-14px sm:text-16px">{{ title }}</div>
        <div v-if="hasSubTilte" class="font12-45">{{ subTitle }}</div>
        <!-- <div v-if="hasBtn" class="marginTop24">
          <button class="upload-btn">{{ btn1 }}</button>
          <button class="upload-btn marginLeft16">{{ btn2 }}</button>
        </div> -->
      </div>
    </div>
  </div>
  <transition name="expand">
    <div v-if="showBottom" class="update_requirement">
      <slot></slot>
    </div>
  </transition>
  </div>
</template>

<script>
export default {
  created () {
    this.IconSrc = require(`@/assets/icon/${this.icon}.png`)
  },
  data () {
    return {
      IconSrc: '',
      showBottom: false
    }
  },
  props: {
    icon: {
      type: String
    },
    title: {
      type: String
    },
    subTitle: {
      type: String
    },
    btn1: {
      type: String
    },
    btn2: {
      type: String
    }
  },
  methods: {
    handleCheckBottom () {
      this.showBottom = !this.showBottom
    },
    checkUpload () {
      // const filesContent = this.$refs?.inputContent?.files
      // console.log(filesContent)
    },
    handleCheckFile (e) {
      const file = []
      const filesContent = this.$refs?.inputContent?.files
      const fileSize = filesContent[0].size / 1024 / 1024
      if (fileSize > 1) {
        alert('this file is too big')
        return false // 检查图片大小
      } else {
        file.push(fileSize)
      }
    }
  },
  computed: {
    hasSubTilte () {
      return this.subTitle
    },
    hasBtn () {
      return this.btn1
    }
  }
}
</script>

<style scoped>
.input{
  display: none;
}
.uploadProfile-container{
  cursor: pointer;
}

.upload-container {
  width: 100%;
  border-radius: 4px;
  background: #ffffff;
  padding: 24px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
}
.upload-left-icon {
  width: 32px;
  height: 32px;
  margin-right: 24px;
}
.upload-btn {
  width: 160px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #bc9a41;
  font-size: 14px;
  font-weight: 400;
  color: #bc9a41;
  line-height: 20px;
  /* outline: none; */
}
@media only screen and (max-width:600px){
.upload-container {
  width: 100%;
  border-radius: 4px;
  background: #ffffff;
  padding: 3vw;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  align-items: center;
}
.upload-left-icon {
  width: 10vw;
  height: auto;
  margin-right: 24px;
}
.upload-btn {
  width: 160px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #bc9a41;
  font-size: 14px;
  font-weight: 400;
  color: #bc9a41;
  line-height: 20px;
  /* outline: none; */
}
}
</style>
