<template>
  <div>
    <div class="top-bar height90">
      <div class="account-title">升级您的账户</div>
    </div>
    <div class="update-bottom">
      <div class="shrink0 updateUnit-identify ">
        <BaseVertifyEmail></BaseVertifyEmail>
        <BaseVertifyIdentity></BaseVertifyIdentity>
        <BaseVertifyPOA></BaseVertifyPOA>
        <button class="sendBtn mt-2vh sm:mt-48px">发送供验证</button>
      </div>
      <div class="shrink0"></div>
    </div>
  </div>
</template>

<script>
import BaseVertifyEmail from '@/components/User/BaseVertifyEmail.vue'
import BaseVertifyIdentity from '@/components/User/BaseVertifyIdentity.vue'
import BaseVertifyPOA from '@/components/User/BaseVertifyPOA.vue'

export default {
  components: {
    BaseVertifyEmail,
    BaseVertifyIdentity,
    BaseVertifyPOA
  },
  computed: {
  }
}

</script>

<style scoped>
.updateUnit:hover {
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.06);
  background: #d7dde4 url("../../../assets/icon/right.png") no-repeat 505px/16px
    16px;
}
.height90 {
  height: 90px;
}
.update-bottom {
  padding-top: 25px;
  padding-left: 24px;
}
.sendBtn {
  width: 100%;
  height: 56px;
  background: #163257;
  border-radius: 4px;
  opacity: 0.2;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  line-height: 22px;
}
.updateUnit-identify {
  width: 540px;
  /* background: #ffffff; */
  margin-bottom: 16px;
  box-sizing: border-box;
}
@media only screen and (max-width:600px){
.height90{
  height: auto;
  padding-bottom: 2vh;
  }
.update-bottom {
  padding: 5vw;
}
  .updateUnit-identify {
  width: 100%;
  /* background: #ffffff; */
  margin-bottom: 16px;
  box-sizing: border-box;
}
.sendBtn {
  display: block;
  width: 80%;
  margin:0 auto;
  height: 6vh;
  background: #163257;
  border-radius: 4px;
  opacity: 0.2;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
}
}
</style>
