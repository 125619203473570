<template>
  <div class="updateUnit-identify">
    <div
      class="updateUnit-top"
      :style="{
        background:
          'url(' +
          require('../../assets/icon/' + getBgIcon) +
          ') no-repeat 505px/16px 16px',
      }"
      @click="handleClick"
    >
      <img class="width24" src="@/assets/icon/identify.png" alt="" />
      <div class=" text-16px sm:text-18px marginLR24">验证您的地址</div>
    </div>
    <transition name="expand">
    <div v-if="isExpand" class="updateUnit-bottom">
    <div class=" text-16px sm:text-24px text-center mt-2vw sm:mt-48px">地址证明</div>
      <div class="font12-45 text-center marginTop6">选择下列之一</div>
      <BaseUpLoadProfile
        class="marginTop16"
        icon="waterFile"
        title="水电费账单"
      >
      <BaseInputFile
        inputId='waterBill'
      ></BaseInputFile>
      </BaseUpLoadProfile>
      <BaseUpLoadProfile
        class=" mt-16px"
        icon="bankFile"
        title="银行对账单"
      >
      <BaseInputFile
        inputId='bankStament'
      ></BaseInputFile>
      </BaseUpLoadProfile>
      <BaseUpLoadProfile
        class=" mt-16px"
        icon="identify"
        title="居住证"
      >
      <BaseInputFile
        inputId='residential'
      ></BaseInputFile>
      </BaseUpLoadProfile>
      <div class="font12-45 text-center marginTop10">
        文档所载日期需在3个月内，而且您的姓名和地址必须清楚显示。
      </div>
      <div class=" text-14px sm:text-20px text-center mt-2vh sm:mt-48px">
        发送您的文件之前，请注意以下方面
      </div>
      <div class=" flex flex-col justify-center sm:pl-140px pl-10vw">
        <div class="font12-45 flex-alignCenter  sm:mt-16px mt-0">
          <img
            class="right_click marginLeft6"
            src="@/assets/icon/right_click.png"
            alt=""
          />页面须完全呈现且清晰可辨。
        </div>
        <div class="font12-45 flex-alignCenter  sm:mt-16px mt-0">
          <img
            class="right_click marginLeft6"
            src="@/assets/icon/right_click.png"
            alt=""
          />不接受过期文件。
        </div>
        <div class="font12-45 flex-alignCenter  sm:mt-16px mt-0">
          <img
            class="right_click marginLeft6"
            src="@/assets/icon/right_click.png"
            alt=""
          />地址须跟您账户上的注册地址一致。
        </div>
        <div class="font12-45 flex-alignCenter  sm:mt-16px mt-0">
          <img
            class="right_click marginLeft6"
            src="@/assets/icon/right_click.png"
            alt=""
          />须采用高画质文件。
        </div>
        <div class="font12-45 flex-alignCenter  sm:mt-16px mt-0">
          <img
            class="right_click marginLeft6"
            src="@/assets/icon/right_click.png"
            alt=""
          />不接受在线截图。
        </div>
      </div>
      <div class=" mt-2vh sm:mt-32px font12-45 text-center">
        如果您在发送 证明文件给我们时有任何问题，请<span
          class="fontGold textUnderline"
          >联系我们</span
        >
      </div>
    </div>
    </transition>
  </div>
</template>

<script>
import BaseUpLoadProfile from '@/components/User/BaseUpLoadProfile.vue'
import BaseInputFile from '@/components/User/BaseInputFile.vue'

export default {
  data () {
    return {
      isExpand: false
    }
  },
  methods: {
    handleClick () {
      this.isExpand = !this.isExpand
    }
  },
  computed: {
    getBgIcon () {
      if (this.isExpand) {
        return 'down.png'
      }
      return 'right.png'
    }
  },
  components: {
    BaseUpLoadProfile,
    BaseInputFile
  }
}
</script>

<style scoped>
.updateUnit-top {
  width: 100%;
  height: 80px;
  padding-left: 26px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.updateUnit-identify {
  width: 540px;
  background: #ffffff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.06);
  margin-bottom: 16px;
  box-sizing: border-box;
}
.updateUnit-bottom {
  width: 100%;
  background: #f5f5f5;
  padding: 32px;
  box-sizing: border-box;
  padding: 20px 32px 32px 32px;
}
@media only screen and (max-width: 600px) {
  .updateUnit-top {
  width: 100%;
  height: 8vh;
  padding-left: 26px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
}
}
</style>
