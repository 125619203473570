<template>
  <div class="updateUnit-mail">
    <div
      class="updateUnit-top"
      :style="{
        background:
          'url(' +
          require('../../assets/icon/' + getBgIcon) +
          ') no-repeat 505px/16px 16px',
      }"
      @click="handleClick"
    >
      <img class="width24" src="@/assets/icon/goldEmail.png" alt="" />
      <div class="sm:text-18px text-16px marginLR24">验证您的电邮地址</div>
    </div>
    <transition name='expand'>
      <div v-if="isExpand" class="updateUnit-bottom">
        <div class="text-center text-12px sm:text-14px text-black-85">
          我们需要验证您的电邮地址：
          <span class="fontGold">{{user.email_address}}</span>
          <div class="marginTop6">点击下面的按钮开始。</div>
        </div>
        <button class="vertifyBtn">验证电邮地址</button>
        <div class=" text-12px text-black-45 mt-3 sm:mt-24px text-center">
          按照电子邮件中的指示
          确认您的电邮地址。若收件箱中没有验证邮件，请检查您的垃圾箱或者垃圾邮件文件夹。
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      isExpand: false
    }
  },
  methods: {
    handleClick () {
      this.isExpand = !this.isExpand
    }
  },
  computed: {
    ...mapState('login', ['user']),
    getBgIcon () {
      if (this.isExpand) {
        return 'down.png'
      }
      return 'right.png'
    }
  }
}

</script>

<style scoped>

.updateUnit-mail {
  width: 540px;
  background: #ffffff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.06);
  margin-bottom: 16px;
  box-sizing: border-box;
}
.updateUnit-top {
  width: 100%;
  height: 80px;
  padding-left: 26px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
}
.updateUnit-bottom {
  width: 100%;
  background: #f5f5f5;
  padding: 32px;
  box-sizing: border-box;
}
/* .updateUnit-mail:hover{
  background-color:  #D7DDE4;
} */
.vertifyBtn {
  width: 320px;
  height: 56px;
  box-sizing: border-box;
  background: #163257;
  padding: 0;
  display: block;
  margin: 32px auto 0px;
  font-weight: 600;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
}
@media only screen and (max-width:600px){
.updateUnit-mail {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.06);
  margin-bottom: 16px;
  box-sizing: border-box;
}
.vertifyBtn {
  width: 60%;
  height: 6vh;
  box-sizing: border-box;
  background: #163257;
  padding: 0;
  display: block;
  margin: 2vh auto 0px;
  font-weight: 600;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
}
.updateUnit-bottom {
  width: 100%;
  background: #f5f5f5;
  padding: 5vw;
  box-sizing: border-box;
}
.updateUnit-top {
  width: 100%;
  height: 8vh;
  padding-left: 26px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
}
}
</style>
