<template>
  <div class="updateUnit-identify">
    <div
      class="updateUnit-top"
      :style="{
        background:
          'url(' +
          require('../../assets/icon/' + getBgIcon) +
          ') no-repeat 505px/16px 16px',
      }"
      @click="handleClick"
    >
      <img class="width24" src="@/assets/icon/identify.png" alt="" />
      <div class="sm:text-18px text-16px marginLR24">验证您的身份</div>
    </div>
    <transition name="expand">
      <div v-if="isExpand" class="updateUnit-bottom">
        <div class="font12-45 text-center">
          请您给我们发送以下所列身份证件及您的照片的清晰易读扫描副本或照片供文件验证。
        </div>
        <div class="font12-45 text-center">
          可接受的格式:文档JPG/PEG和PDF。照片JPG或PNG.
        </div>
        <div class="font12-45 text-center">验证最多需要2天即可完成。</div>
        <div class=" text-16px sm:text-24px text-center mt-5vw sm:mt-48px">身份证明</div>
        <div class=" text-orange-main font-600 text-12px text-center marginTop6">选择下列之一</div>
        <BaseUpLoadProfile
          class="marginTop16"
          icon="passport"
          title="护照"
          subTitle="显示您的照片、出生日期以及完整的护照号码。"
        >
        <BaseInputFile
        inputId ='PassPort'
        ></BaseInputFile>
        </BaseUpLoadProfile>
        <BaseUpLoadProfile
          class="marginTop16"
          icon="passport"
          title="枪证"
          subTitle="显示您的照片、出生日期以及完整的护照号码。"
        >
        <BaseInputFile
        inputId='GunLiscense'
        ></BaseInputFile>

        </BaseUpLoadProfile>
        <BaseUpLoadProfile
          class="marginTop16"
          icon="identify"
          title="国民身份证 18+ 驾照 "
          subTitle="您的卡片正面和背面清楚显示所有信息。"
          btn1="卡片正面"
          btn2="卡片背面"
        >
        <div>
            <BaseUpLoadProfile
            class="marginTop16"
              icon="identify"
              title="国民身份证 18+ 驾照 "
              subTitle="您的卡片正面和背面清楚显示所有信息。"
              btn1="卡片正面"
              btn2="卡片背面"
            >
            <BaseInputFile></BaseInputFile>
            </BaseUpLoadProfile>
            <BaseInputFile></BaseInputFile>
        </div>
        </BaseUpLoadProfile>

      </div>
    </transition>
  </div>
</template>

<script>
import BaseUpLoadProfile from '@/components/User/BaseUpLoadProfile.vue'
import BaseInputFile from '@/components/User/BaseInputFile.vue'

export default {
  components: {
    BaseUpLoadProfile,
    BaseInputFile
  },
  data () {
    return {
      isExpand: false
    }
  },
  methods: {
    handleClick () {
      this.isExpand = !this.isExpand
    }
  },
  computed: {
    getBgIcon () {
      if (this.isExpand) {
        return 'down.png'
      }
      return 'right.png'
    }
  }
}
</script>

<style scoped>
.updateUnit-top {
  width: 100%;
  height: 80px;
  padding-left: 26px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
}
.updateUnit-identify {
  width: 540px;
  background: #ffffff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.06);
  margin-bottom: 16px;
  box-sizing: border-box;
}
/* .updateUnit-identify:hover{
  background-color:  #D7DDE4;
} */
.updateUnit-bottom {
  width: 100%;
  background: #f5f5f5;
  padding: 32px;
  box-sizing: border-box;
  padding: 20px 32px 32px 32px;
}
.right_click {
  width: 16px;
  height: 16px;
}
@media only screen and (max-width:600px){
  .updateUnit-top {
  width: 100%;
  height: 8vh;
  padding-left: 26px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
}
}
</style>
