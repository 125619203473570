<template>
  <div class="inputCom">
    <input
      type="file"
      @change="imgChange"
      accept="image/png,image/gif,image/jpeg"
      class="updateFile cursor-pointer"
      :id='inputId'
      ref="fileInput"
      >
      <label class="labelSty" :for="inputId"></label>
    <img
    v-show="isUploadEmpty"
    class="add-png cursor-pointer"
    @click="handleIconClick"
    src="@/assets/icon/add.png" alt="">
    <div
    v-show="!isUploadEmpty"
    class=" w-full h-full flex
    items-center justify-center relative">
    <img class="uploadedImg" :src="imgSrc"
    alt="">
    <button
    @click="reloadImg"
    class=" absolute right-0">X</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    inputId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      imgSrc: ''
    }
  },
  methods: {
    handleIconClick () {
      this.$refs.fileInput.click()
    },
    imgChange (e) {
      const FR = new FileReader()
      // 创建对象
      FR.readAsDataURL(e.target.files[0])
      // 解析对象为Base64
      FR.onloadend = (e) => {
        this.imgSrc = e.target.result
      }
      FR.onloadstart = () => {
      }
    },
    reloadImg () {
      console.log('sd')
      this.imgSrc = ''
    }
  },
  computed: {
    isUploadEmpty () {
      return this.imgSrc === ''
    }
  }
}
</script>

<style scoped>
.inputCom{
  width: 100%;
  height: 200px;
  background-color: #f5f5f5;
  box-sizing: border-box;
  border-width: 0px 2px 2px 2px;
  border-color: #fff;
  border-style: solid;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-png{
  width: 40px;
  height: 40px;
  position: absolute;
  top: calc( 50% - 20px);
  left: calc( 50% - 20px);
}
.labelSty{
  display: block;
  position: absolute;
   cursor: pointer;
   width: 100%;
   height: 100%;
}
.uploadedImg{
  height: 100%;
  display: block;
}
.updateFile{
  display: none;
}
@media only screen and (max-width:600px){
.inputCom{
  width: 100%;
  height: 20vh;
  background-color: #f5f5f5;
  box-sizing: border-box;
  border-width: 0px 2px 2px 2px;
  border-color: #fff;
  border-style: solid;
  position: relative;
}
.add-png{
  width: 40px;
  height: 40px;
  position: absolute;
  top: calc( 50% - 20px);
  left: calc( 50% - 20px);
}

}
</style>
